@import '../../../styles/var';

.tradingPlansSwiper {
    position: relative;
    min-height: 100%;
    @media(max-width: $md3+px) {
        padding: 90px 40px 60px 40px;
    }   
    @media(max-width: $md6+px) {
        padding: 90px 10px 50px 10px;
    }
    .tradingPlansSwiperSlide {
        height: auto!important;
        width: 440px!important;
        &:nth-child(1) {
            div {
                // background-image: url('../../../assets/img/TradingPage/TradingPlans/card_bg_01.png');
            
            }
        }
        &:nth-child(2) {
            div {
                // background-image: url('../../../assets/img/TradingPage/TradingPlans/card_bg_02.png');
            }
        }
        &:nth-child(3) {
            div {
                // background-image: url('../../../assets/img/TradingPage/TradingPlans/card_bg_03.png');
            }
        }
        @media(max-width: $md4+px) {
            width: 400px!important;
        }
        @media(max-width: $md6+px) {
            width: 100%!important;
        }
    }
    .swiper-pagination {
        .swiper-pagination-bullet {
            transition: all .3s;
            width: 15px;
            height: 15px;
        }
        .swiper-pagination-bullet-active {
            background: #a99658;
            width: 40px;
            border-radius: 500px;
        }
    }
}