@import "../../../styles/var";
.three {
}
.threeBody {
}
.threeTitle {
    margin-bottom: 70px;
    @media (max-width: $md5+px) {
        margin-bottom: 60px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 50px;
    }
}
.row {
    display: flex;
    justify-content: space-between;
    text-align: center;
    @media (max-width: $md2+px) {
        flex-wrap: wrap;
    }
}
.rowColumn {
    @media (max-width: $md2+px) {
        flex: 0 1 33%;
        &:first-child {
            margin-bottom: 40px;
        }
    }
    @media (max-width: 650px) {
        flex: 0 1 50%;
        &:nth-child(3) {
            margin-bottom: 40px;
        }
    }
}
.rowColumnImg {
    margin-bottom: 20px;
    img {
        height: 80px;
        @media (max-width: $md5+px) {
            height: 60px;
        }
        @media (max-width: $md6+px) {
            height: 50px;
        }
    }
}
.rowColumnText {
    max-width: 224px;
    margin: 0 auto;
}
