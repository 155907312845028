@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 40px;
    @media(max-width: $md6+px) {
        margin-top: 0px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/TradingPage/Banner/bg.png');
        background-repeat: no-repeat;
        background-position: right center;
        background-size: cover;
        @media(max-width: $md2+px) {
            background-position: center center;
        }
        // @media(max-width: $md6+px) {
        //     background-image: url('../../../assets/img/TradingPage/Banner/bg_mob.png');
        //     background-position: top center;
        // }
    }
    &Body {
        position: relative;
        padding: 70px 0;
        @media(max-width: $md1+px) {
            padding: 60px 0;
        }
        @media(max-width: $md4+px) {
            padding: 50px 0;
        }
        .title {
            max-width: 700px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 42px;
            line-height: 51px;
            background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(76.82deg, #CFCFCF 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #D5E4E7 85.34%, #576265 91.31%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            background-blend-mode: color, overlay, normal;
            @media(max-width: $md4+px) {
                font-size: 38px;
                line-height: 46px;
            }
            @media(max-width: $md6+px) {
                font-size: 25px;
                line-height: 30px;
                text-align: center;
            }
        }
        .text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 120%;
            color: #FFFFFF;
            margin: 30px 0 50px 0;
            @media(max-width: $md6+px) {
                font-size: 14px;
                text-align: center;
            }
        }
        .btn {
            @media(max-width: $md6+px) {
                text-align: center;
            }
        }
    }
}