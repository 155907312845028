@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 40px;
    background: #F7F7F7;
    max-width: 2500px;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: $md6+px) {
        margin-top: 0px;
    }
    &Body {
        padding: 87px 0 77px 0;
        @media(max-width: $md1+px) {
            padding: 70px 0 60px 0;
        }
        @media(max-width: $md5+px) {
            padding-bottom: 0;
        }
        @media(max-width: $md6+px) {
            padding-top: 50px;
        }
        .title {
            position: relative;
            z-index: 4;
            margin-bottom: 50px;
            max-width: 700px;
            text-align: left;
            @media(max-width: $md4+px) {
                max-width: 540px;
            }
            @media(max-width: $md6+px) {
                max-width: 340px;
            }
        }
        .btn {
            position: relative;
            z-index: 4;
        }
    }
    .human {
        position: absolute;
        z-index: 3;
        width: 512px;
        bottom: 0;
        left: 200px;
        right: 0;
        margin: 0 auto;
        @media(max-width: $md1+px) {
            width: 470px;
            left: 400px;
        }
        @media(max-width: $md2+px) {
            left: 500px;
        }
        @media(max-width: $md3+px) {
            width: 400px;
            left: 600px;
        }
        @media(max-width: $md4+px) {
            left: 500px;
        }
        @media(max-width: $md5+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            left: unset;
            right: unset;
            bottom: unset;
        }
        @media(max-width: $md6+px) {
            width: 365px;
            left: -10px;
            margin-top: 34px;
        }
    }
    .town {
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        height: 100%;
        @media(max-width: $md3+px) {
            right: -100px;
        }
        @media(max-width: $md5+px) {
            right: 0;
            height: 360px;
            top: unset;
            bottom: 0;
        }
        @media(max-width: $md6+px) {
            left: -20px;
            margin: 0 auto;
        }
    }
}