@import "../../../styles/var";
.five {
}
.fiveBody {
}
.fiveTitle {
    margin-bottom: 30px;
}
.fiveSubtitle {
    font-weight: 600;
    max-width: 700px;
    text-align: center;
    margin: 0 auto 100px auto;
    @media (max-width: $md4+px) {
        max-width: 550px;
    }
}
.fiveRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md2+px) {
        flex-wrap: wrap;
        max-width: 700px;
        margin: 0 auto;
    }
    @media (max-width: $md5+px) {
        justify-content: center;
    }
}
.fiveRowColumn {
    flex: 0 1 330px;
    @media (max-width: $md1+px) {
        flex: 0 1 300px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 50%;

        margin-bottom: 70px;
        &:nth-child(3) {
            margin-bottom: 0;
        }
        &:nth-child(4) {
            margin-bottom: 0;
        }
    }
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
        margin-bottom: 70px !important;
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}
.fiveRowColumnItem {
    max-width: 330px;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding: 138px 36px 25px 36px;
    box-shadow: 0px 179.729px 70.8022px rgba(0, 0, 0, 0.01), 0px 99.8493px 59.9096px rgba(0, 0, 0, 0.05),
        0px 45.386px 45.386px rgba(0, 0, 0, 0.09), 0px 10.8926px 25.4162px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);

    background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    border-width: 2.5623px 0px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(17.9361px);

    border-radius: 42.7051px;
    @media (max-width: $md1+px) {
        padding: 138px 20px 25px 20px;
    }
    @media (max-width: $md6+px) {
        padding: 138px 36px 25px 36px;
    }
}
.fiveRowColumn {
    .fiveRowColumnItemImg {
        position: absolute;
    }
    &:nth-child(1) {
        .fiveRowColumnItemImg {
            width: 292.41px;
            height: 159px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            background: url("../../../assets/img/main/5/1.png") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(2) {
        .fiveRowColumnItemImg {
            width: 191px;
            height: 159px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            background: url("../../../assets/img/main/5/2.png") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(3) {
        .fiveRowColumnItemImg {
            width: 187px;
            height: 159px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            background: url("../../../assets/img/main/5/3.png") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(4) {
        .fiveRowColumnItemImg {
            width: 154px;
            height: 159px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            background: url("../../../assets/img/main/5/4.png") 0 0 / contain no-repeat;
        }
    }
}
.fiveRowColumnItemImg {
}
.fiveRowColumnItemTitle {
    text-align: center;
    margin-bottom: 25px;
    font-weight: 800 !important;
}
.fiveRowColumnItemList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.fiveRowColumnItemListItem {
    flex: 0 1 50%;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: "";
        display: inline-block;
        left: 5px;
        top: 10px;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #373737;
        @media (max-width: $md4+px) {
            top: 8px;
        }
        @media (max-width: $md6+px) {
            top: 6px;
        }
    }
}
