@import "../../../styles/_var.scss";
.headerNavLink {
    display: block !important;
    font-weight: 500;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    color: #2e2e2e;
    margin-left: 60px;
    padding: 4px 0;
    @media (max-width: $md1+px) {
        margin-left: 30px;
    }
    @media (max-width: $md3+px) {
        margin: 0 auto 30px auto !important;
        display: inline-block !important;
    }

    &:first-child {
        margin-left: 0;
    }
    &.active {
        position: relative;
        color: #7B622F;
        // &:after {
        //     content: "";
        //     display: inline-block;
        //     left: 0;
        //     bottom: -2px;
        //     position: absolute;
        //     width: 100%;
        //     height: 2px;
        //     background-color: #2e2e2e;
        // }
        // &:after {
        //     content: "";
        //     display: inline-block;
        //     left: 0;
        //     bottom: -4px;
        //     position: absolute;
        //     width: 100%;
        //     height: 2px;
        //     background-color: #06162d;
        // }
    }
}
