@import '../../../styles/var';

.topTrading {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .blockbg {
        position: relative;
        .bg {
            position: absolute;
            z-index: 1;
            right: -200px;
            bottom: 0;
            height: 802px;
            @media(max-width: $md1+px) {
                height: 650px;
            }
            @media(max-width: $md2+px) {
                height: 550px;
            }
            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }
    .title {
        text-align: left;
        position: relative;
        z-index: 3;
    }
    .text {
        position: relative;
        z-index: 3;
        max-width: 700px;
        margin-top: 30px;
        @media(max-width: $md1+px) {
            max-width: 650px;
        }
        @media(max-width: $md3+px) {
            max-width: 100%;
        }
    }
    .block {
        position: relative;
        z-index: 3;
        margin-top: 120px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media(max-width: $md1+px) {
            margin-top: 100px;
        }
        @media(max-width: $md3+px) {
            justify-content: unset;
            flex-direction: column;
            gap: 80px;
            margin-top: 40px;
        }
        @media(max-width: $md6+px) {
            margin-top: 25px;
        }
        .image {
            img {
                width: 584px;
                @media(max-width: $md1+px) {
                    width: 500px;
                }
                @media(max-width: $md3+px) {
                    width: 600px;
                }
                @media(max-width: $md5+px) {
                    width: 400px;
                }
                @media(max-width: $md6+px) {
                    width: 286px;
                }
            }
        }
        .content {
            width: 700px;
            @media(max-width: $md1+px) {
                max-width: 650px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
                max-width: 100%;
            }
            p {
                margin: 30px 0 15px 0;
            }
            ul {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                gap: 13px;
                @media(max-width: $md6+px) {
                    padding-left: 20px;
                }
                li {
                    list-style-position: outside;
                    list-style-type: disc;
                }
            }
            .btn {
                margin-top: 45px;
            }
        }
    }
}