@import '../../../styles/var';

.ourAdvantages {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: left;
    }
    .text {
        margin: 30px 0 60px 0;
        @media(max-width: $md6+px) {
            margin: 15px 0 50px 0;
        }
    }
    .content {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
        align-items: flex-start;
        @media(max-width: $md1+px) {
            margin-top: 60px;
        }
        @media(max-width: $md3+px) {
            flex-direction: column;
        }
        @media(max-width: $md4+px) {
            margin-top: 40px;
        }
        .tabs {
            position: relative;
            z-index: 5;
            display: flex;
            flex-direction: column;
            gap: 25px;
            .tab {
                cursor: pointer;
                position: relative;
                padding-left: 30px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0px;
                    width: 8px;
                    height: 100%;
                    background: #686868;
                    border-radius: 2px;
                }
                &.current {
                    &::before {
                        background: #1F1F1F;
                    }
                    .tabTitle {
                        background: #1F1F1F;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                    .tabText {
                        display: block;
                    }
                }
                &Title {
                    color: #686868;
                    font-weight: 800;
                    display: inline-flex;
                    @media(max-width: $md6+px) {
                        font-size: 16px!important;
                    }
                }
                &Text {
                    margin-top: 30px;
                    width: 700px;
                    display: none;
                    @media(max-width: $md1+px) {
                        width: 650px;
                    }
                    @media(max-width: $md2+px) {
                        width: 600px;
                    }
                    @media(max-width: $md3+px) {
                        width: 100%;
                    }
                    @media(max-width: $md6+px) {
                        margin-top: 15px;
                    }
                }
            }
        }
        .image {
            position: absolute;
            z-index: 2;
            right: 0;
            bottom: 0;
            width: 614px;
            @media(max-width: $md1+px) {
                width: 500px;
            }
            @media(max-width: $md2+px) {
                width: 450px;
            }
            @media(max-width: $md3+px) {
                position: relative;
                display: flex;
                margin: 60px auto 0 auto;
            }
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
            @media(max-width: $md6+px) {
                margin-toP: 30px;
                width: 100%;
            }
        }
    }
}