@import "../../../styles/var";
.seven {
}
.sevenBody {
}
.sevenRow {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 700px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.sevenRowColumn {
    display: flex;
    @media (max-width: 700px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
        justify-content: center;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.sevenRowColumnNumber {
    font-weight: 800;
    font-size: 100px;
    line-height: 1;
    background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(76.82deg, #576265 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #576265 55.72%, #576265 77.23%, #757A7B 85.34%, #576265 91.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    background-blend-mode: color, overlay, normal;
    margin-right: 5px;
    @media (max-width: $md1+px) {
        font-size: 80px;
    }
    @media (max-width: $md3+px) {
        font-size: 60px;
    }
    @media (max-width: $md4+px) {
        font-size: 50px;
    }
}
.sevenRowColumnInfo {
    position: relative;
    top: 5px;
    max-width: 200px;
}
.sevenRowColumnInfoTitle {
    font-weight: 800;
    font-size: 30px;
    line-height: 1.2;
    color: #2e2e2e;
    @media (max-width: $md3+px) {
        font-size: 20px;
    }
    @media (max-width: $md4+px) {
        font-size: 14px;
    }
}
.sevenRowColumnInfoSubtitle {
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
    @media (max-width: $md4+px) {
        font-size: 13px;
    }
}
.sevenBtn {
    display: flex;
    justify-content: center;
}
