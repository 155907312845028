@import "../../../styles/var";
.four {
}
.fourBody {
}
.fourRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.fourRowLeft {
    flex: 0 1 613px;
    @media (max-width: $md3+px) {
        flex: 0 1 513px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 40px;
    }
}
.fourRowLeftForm {
}
.fourRowLeftFormTitle {
    margin-bottom: 30px;
    text-align: left !important;
}
.fourRowLeftFormOverTitle {
    font-weight: 600 !important;
    margin-bottom: 15px;
}
.fourRowLeftFormList {
    margin-bottom: 30px;
}
.fourRowLeftFormListItem {
    margin-bottom: 15px;
    position: relative;
}
.fourRowLeftName,
.fourRowLeftEmail {
    height: 50px;
    border: 1px solid #2E2E2E;
    border-radius: 20px;
    padding: 0 25px;
    margin-bottom: 20px;
    input {
        font-weight: 400;
        font-size: 14px;

        color: #2e2e2e;
        background-color: transparent;
        width: 100%;
        height: 100%;
    }
}
.fourRowLeftTextarea {
    height: 100px;
    border: 1px solid #2E2E2E;
    border-radius: 20px;
    padding: 16px 25px;
    margin-bottom: 20px;
    textarea {
        font-weight: 400;
        font-size: 14px;

        color: #2e2e2e;
        background-color: transparent;
        width: 100%;
        height: 100%;
        resize: none;
        &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #2E2E2E;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 25px;
            background-color: #2E2E2E;
        }
    }
}
.fourRowLeftEmail {
    margin-bottom: 20px;
}
.fourRowLeftPhone {
    height: 50px;
    border: 1px solid #2E2E2E;
    border-radius: 20px;
    margin-bottom: 20px;
}
.fourRowLeftSign {
    // background-color: #2E2E2E;
    border-radius: 20px;
    border: 1px solid transparent;
    height: 50px;
    margin-bottom: 20px;
    font-weight: 800;
    font-size: 16px;
    line-height: 50px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(81.52deg, #576265 9.69%, #9EA1A1 24.17%, #848B8A 48.19%, #576265 56.29%, #576265 79.01%, #757A7B 87.57%, #576265 93.87%);
    background-blend-mode: color, overlay, normal;
    &:hover {
        background: #ffffff;
        color: #2E2E2E;
        border-color: #2E2E2E;
    }
}
.fourRowLeftPolicy,
.fourRowLeftCom {
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    color: #3f3f3f;
    a {
        color: #2E2E2E;
    }
    position: relative;
    padding-left: 30px;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 5px;
        border: 3px solid #2e2e2e;
    }
    &:before {
        content: "";
        display: inline-block;
        left: 5px;
        top: 5px;
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: #2e2e2e;
        transition: all 0.3s linear;
        opacity: 0;
    }
    &.active {
        &:before {
            opacity: 1;
        }
    }
}
.fourRowLeftCom {
    margin-top: 15px;
}
.fourRowRight {
    flex: 0 1 600px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md4+px) {
        position: relative;
        left: 120px;
    }
    @media (max-width: $md5+px) {
        left: 140px;
    }
    @media (max-width: $md6+px) {
        left: 0px;
        text-align: center;
    }
    img {
        width: 100%;
        @media (max-width: $md5+px) {
            width: 336px;
        }
        @media (max-width: $md6+px) {
            width: 262px;
            margin: 0 auto;
            text-align: center;
        }
    }
}
