@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 100px;
    background: #1F1F1F;
    @media(max-width: $md1+px) {
        margin-top: 60px;
    }
    @media(max-width: $md6+px) {
        margin-top: 0;
    }
    &Body {
        position: relative;
        z-index: 3;
        padding: 60px 0;
        @media(max-width: $md4+px) {
            padding: 40px 0 120px 0;
        }
        @media(max-width: $md6+px) {
            padding-bottom: 112px;
        }
        .title {
            text-align: left;
            color: #FFFFFF;
        }
        .text {
            margin: 30px 0 50px 0;
            color: #FFFFFF;
            max-width: 500px;
            @media(max-width: $md4+px) {
                max-width: 400px;
            }
            @media(max-width: $md6+px) {
                max-width: 340px;
            }
        }   
    }
    .image {
        position: absolute;
        z-index: 1;
        width: 876px;
        right: 0;
        top: -115px;
        @media(max-width: $md1+px) {
            width: 750px;
            top: -60px;
        }
        @media(max-width: $md2+px) {
            width: 650px;
        }
        @media(max-width: $md4+px) {
            width: 500px;
            top: unset;
            bottom: -100px;
        }
        @media(max-width: $md5+px) {
            width: 450px;
        }
        @media(max-width: $md6+px) {
            width: 340px;
            bottom: -60px;
        }
    }
}