@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 40px;
    // background: #3935FF;
    background-image: url('../../../assets/img/EducationPage/Banner/bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @media(max-width: $md6+px) {
        margin-top: 0;
    }
    &Body {
        padding: 35px 0;
        position: relative;
        @media(max-width: $md4+px) {
            padding-bottom: 100px;
        }
        @media(max-width: $md6+px) {
            padding: 35px 0 77px 0;
        }
        .title {
            text-align: left;
            margin-bottom: 50px;
            position: relative;
            z-index: 4;
            max-width: 700px;
            background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(79.1deg, #576265 -8.25%, #9EA1A1 6.72%, #848B8A 31.56%, #D7F0F7 78.8%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            background-blend-mode: color, overlay, normal;
            @media(max-width: $md4+px) {
                max-width: 600px;
            }
            @media(max-width: $md6+px) {
                max-width: 340px;
            }
        }
        .btn {
            position: relative;
            z-index: 4;
        }
        .mac {
            position: absolute;
            z-index: 3;
            height: 309px;
            bottom: 5px;
            right: 100px;
            @media(max-width: $md1+px) {
                right: 50px;
                height: 280px;
            }
            @media(max-width: $md2+px) {
                right: 0px;
            }
            @media(max-width: $md3+px) {
                height: 170px;
            }
            @media(max-width: $md5+px) {
                height: 180px;
                right: 0px;
            }
            @media(max-width: $md6+px) {
                height: 150px;
                right: -20px;
                bottom: 0;
            }
        }
    }
}