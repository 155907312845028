@import "../../../styles/var";

.footer {
    position: relative;
    margin-top: 150px;
    padding: 30px 0 70px 0;
    border-top: 1px solid #2e2e2e;
    color: #2e2e2e;
    @media (max-width: $md4+px) {
        margin-top: 120px;
    }
    @media (max-width: $md6+px) {
        margin-top: 80px;
    }
    .content {
        display: grid;
        grid-template-columns: 927px 1fr 326px;
        gap: 100px;
        @media (max-width: $md1+px) {
            grid-template-columns: 700px 1fr 300px;
            gap: 60px;
        }
        @media (max-width: $md2+px) {
            grid-template-columns: repeat(2, 1fr);
            .docs {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 1;
                grid-column-end: 1;
            }
            .about {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: 2;
                grid-column-end: 2;
            }
            .info {
                grid-row-start: 2;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        @media (max-width: $md6+px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            .docs {
                grid-row-start: 1;
                grid-row-end: 1;
                grid-column-start: unset;
                grid-column-end: unset;
            }
            .about {
                grid-row-start: 2;
                grid-row-end: 2;
                grid-column-start: unset;
                grid-column-end: unset;
            }
            .info {
                grid-row-start: 3;
                grid-row-end: 3;
                grid-column-start: unset;
                grid-column-end: unset;
            }
        }
        .info {
            display: flex;
            flex-direction: column;
            gap: 15px;
            font-family: $fontfamily;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #2e2e2e;
            @media (max-width: $md6+px) {
                font-size: 10px;
                line-height: 15px;
            }
            .row {
                &Title {
                    font-weight: 700;
                }
                &Text {
                }
            }
        }
        .docs {
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media (max-width: $md6+px) {
                gap: 15px;
            }
            a {
                cursor: pointer;
                color: #2e2e2e;
            }
        }
        .about {
            display: flex;
            flex-direction: column;
            gap: 30px;
            @media (max-width: $md6+px) {
                gap: 15px;
            }
            a {
                display: block;
                cursor: pointer;
            }
        }
        .docs,
        .about {
            font-family: $fontfamily;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #2e2e2e;
            @media (max-width: $md6+px) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

.rowText {
    span {
        font-weight: 600;
        margin-right: 5px;
    }
}
