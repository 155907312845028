@import '../../../styles/var';

.embrace {
    position: relative;
    margin-top: 308px;
    background: #1E1E1E;
    @media(max-width: $md1+px) {
        margin-top: 220px;
    }
    @media(max-width: $md3+px) {
        margin-top: 180px;
    }
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    &Body {
        padding: 60px 0 50px 0;
        position: relative;
        @media(max-width: $md4+px) {
            padding: 50px 0 0 0;
        }
        .title {
            position: relative;
            z-index: 5;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 42px;
            line-height: 51px;
            background: linear-gradient(0deg, #FFE7B7, #FFE7B7), linear-gradient(47.72deg, rgba(255, 255, 255, 0) 18.09%, #FFFFFF 70.39%), linear-gradient(77.87deg, #576265 11.19%, #9EA1A1 87.18%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            background-blend-mode: color, overlay, normal;
            max-width: 800px;
            @media(max-width: $md4+px) {
                font-size: 38px;
                line-height: 46px;
            }
            @media(max-width: $md6+px) {
                font-size: 25px;
                line-height: 30px;
                text-align: center;
            }
            span {
                // position: relative;
                // z-index: 5;
                // &::before {
                //     content: '';
                //     position: absolute;
                //     top: 0;
                //     right: 0;
                //     height: 100%;
                //     width: 200%;
                //     background: #FFFFFF;
                //     z-index: -1;
                // }
            }
        }
        .text {
            margin: 30px 0 50px 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #FFFFFF;
            @media(max-width: $md4+px) {
                font-size: 16px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                text-align: center;
            }
        }
        .btn {
            @media(max-width: $md6+px) {
                text-align: center;
            }
        }
        .human {
            position: absolute;
            z-index: 1;
            right: 0;
            bottom: 0;
            width: 742px;
            @media(max-width: $md1+px) {
                width: 550px;
            }
            @media(max-width: $md2+px) {
                width: 500px;
                right: -50px;
            }
            @media(max-width: $md3+px) {
                width: 400px;
                right: 0;
            }
            @media(max-width: $md4+px) {
                left: 0;
                position: relative;
                display: flex;
                margin: 20px auto 0 auto;
                width: 450px;
            }
            @media(max-width: 600px) {
                width: 316px
            }
        }
    }
}