@import '../../../styles/var';

.discoverNewWr {
    position: relative;
}

.discoverNew {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .text {
        position: relative;
        z-index: 4;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: #2E2E2E;
        max-width: 700px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        b {
            font-weight: 600;
        }
        @media(max-width: $md6+px) {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #2E2E2E;
        }
    }
    .img {
        position: absolute;
        z-index: 3;
        width: 310px;
        top: -80px;
        right: 200px;
        @media(max-width: $md1+px) {
            width: calc(310px * 0.9);
            top: -60px;
            right: 70px;
        }
        @media(max-width: $md3+px) {
            width: calc(310px * 0.8);
            top: unset;
            bottom: -20px;
            right: calc(50% - 80px);
        }
        @media(max-width: $md6+px) {
            width: 214px;
            bottom: -70px;
            right: calc(50% - 70px);
        }
    }
    .img_blue {
        position: absolute;
        z-index: 2;
        width: 270px;
        top: -60px;
        right: 120px;
        @media(max-width: $md1+px) {
            width: calc(270px * 0.9);
            top: -40px;
            right: 0px;
        }
        @media(max-width: $md3+px) {
            width: calc(270px * 0.8);
            top: unset;
            bottom: -5px;
            right: calc(50% - 140px);
        }
        @media(max-width: $md6+px) {
            width: 187px;
            bottom: -55px;
            right: calc(50% - 130px);
        }
    }
    .chart {
        position: absolute;
        z-index: 1;
        width: 794px;
        right: -140px;
        top: -50px;
        @media(max-width: $md1+px) {
            right: -350px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            right: unset;
            top: unset;
            left: 0;
            right: 0;
            margin: 60px auto 0 auto;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
        @media(max-width: $md5+px) {
            width: 780px;
            left: -15px;
        }
        @media(max-width: $md6+px) {
            width: calc(100% + 20px);
            left: -10px;
            margin-top: 77px;
        }
    }
}