@import "./_var.scss";
body,
html {
    background-color: #fff;
    color: #2e2e2e;
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}
.font-42 {
    font-family: $fontfamily;
    font-weight: 800;
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    // color: #3935ff;
    background: linear-gradient(93.72deg, #C9B46E 7.07%, #63490E 38.64%, #D6D6A1 73.99%, #3B3C2D 87.42%, #020403 105.46%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @media (max-width: $md4+px) {
        font-size: 38px;
        line-height: 46px;
    }
    @media (max-width: $md6+px) {
        font-size: 25px;
        line-height: 30px;
    }
}

.font-20 {
    font-family: $fontfamily;

    font-size: 20px;
    line-height: 140%;

    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.font-18 {
    font-family: $fontfamily;
    font-size: 18px;
    line-height: 140%;
    color: #2e2e2e;
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.global-pb {
    padding-bottom: 150px;
    @media (max-width: $md4+px) {
        padding-bottom: 120px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 80px;
    }
}
.global-pt {
    padding-top: 150px;
    @media (max-width: $md4+px) {
        padding-top: 120px;
    }
    @media (max-width: $md6+px) {
        padding-top: 80px;
    }
}
.react-tel-input {
    // box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
    // display: block;

    // background: rgba(0, 0, 0, 0.3);
    // background: transparent;
    background-color: transparent !important;
    border-radius: 20px;
    height: 100%;
    .flag-dropdown {
        border: none;
        border-right: 1px solid #a99658;
        border-radius: 20px 0px 0 20px !important;
        background-color: transparent;
        &.open {
        }

        .selected-flag {
            padding: 0 0 0 25px;
            width: 60px;
            .flag {
            }
            &.open {
                // background: rgba(0, 0, 0, 0.3);
                background-color: transparent;
                border: none;
                border-radius: 25px;
            }
        }
        .selected-flag:hover {
            background-color: transparent !important;
        }
        .selected-flag:focus {
            background-color: transparent !important;
        }
    }
    .form-control {
        height: 100%;
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent !important;
        border-radius: 20px;
        padding-left: 75px;
        &::placeholder {
        }
    }
    .country-list {
        .country:hover {
        }
        .country.highlight {
        }
    }
}


.preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    opacity: 1;
    transition: all 0.2s;
    &.hidePreloader {
      opacity: 0;
      pointer-events: none;
    }
}

.lds-facebook {
    margin-left: calc(50% - 40px);
    margin-top: calc(50vh - 50px);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #D4B180;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  