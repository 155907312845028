@import "../../../styles/var";
.second {
}
.secondBody {
    padding: 20px 0;
    // background-color: #3935ff;
    background-image: url('../../../assets/img/main/2/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.rowWrapper {
    max-width: 1454px;
    margin: 0 auto;
}
.row {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md5+px) {
        flex-wrap: wrap;
    }
}
.rowColumn {
    flex: 0 1 245px;
    text-align: center;
    @media (max-width: $md4+px) {
        flex: 0 1 200px;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
        margin-bottom: 50px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.rowColumnImg {
    margin-bottom: 20px;
    img {
        height: 50px;
        @media (max-width: $md6+px) {
            height: 40px;
        }
    }
}
.rowColumnText {
    color: #fff;
    @media (max-width: $md5+px) {
        max-width: 200px;
        margin: 0 auto;
    }
}
