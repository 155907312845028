@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}
