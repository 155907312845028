@import '../../../styles/var';

.whyChoose {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {

    }
    .tabs {
        max-width: 1068px;
        margin: 70px auto 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media(max-width: $md4+px) {
            margin-top: 50px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .tab {
            cursor: pointer;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            box-shadow: 0px 470px 188px rgba(0, 0, 0, 0.01), 0px 264px 159px rgba(0, 0, 0, 0.05), 0px 117px 117px rgba(0, 0, 0, 0.09), 0px 29px 65px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
            &Title {
                background: #1F1F1F;
                padding: 13px 40px;
                position: relative;
                align-items: center;
                gap: 10px;
                display: grid;
                grid-template-columns: 1fr 14px;
                font-weight: 800;
                font-size: 20px;
                line-height: 24px;
                color: #FFFFFF;
                @media(max-width: $md4+px) {
                    font-size: 18px;
                    line-height: 120%;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    line-height: 17px;
                    padding: 16px 15px;
                }
                &Status {
                    transition: all .3s;
                    &.active {
                        transform: rotate(90deg);
                    }
                }
            }
            &Content {
                background: #FFFFFF;
                color: #2E2E2E;
                padding: 30px 40px;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                @media(max-width: $md4+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    gap: 10px;
                    padding: 20px 15px;
                }
                &Body {
                    max-width: 700px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    @media(max-width: $md6+px) {
                        gap: 10px;
                    }
                }
                ol, ul {
                    padding-left: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    @media(max-width: $md6+px) {
                        gap: 10px;
                        padding-left: 20px;
                    }
                    li {
                        list-style-position: outside;
                    }
                }
                ul {
                    li {
                        list-style-type: disc;
                    }
                }
            }
        }
    }
}