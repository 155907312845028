@import '../../../styles/var';

.whyChoose {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {

    }
    .container {
        @media(max-width: $md5+px) {
            max-width: 100%!important;
            width: 100%!important;
            padding: 0!important
        }
    }
    .tableWr {
        max-width: 800px;
        margin: 75px auto 0 auto;
        @media(max-width: $md4+px) {
            margin-top: 50px;
        }
        @media(max-width: $md5+px) {
            padding: 0 10px 10px 10px!important;
            overflow: scroll;
            overflow-y: hidden;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
        table {
            border-spacing: 0;
            width: 100%;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            color: #373737;
            @media(max-width: $md5+px) {
                min-width: 700px;
            }
            @media(max-width: $md6+px) {
                font-size: 12px;
                line-height: 15px;
                min-width: 500px;
            }
            tbody {
                tr {
                    td {
                        padding: 15px 0;
                        border-bottom: 1px solid #AAAAAA;
                        @media(max-width: $md6+px) {
                            padding: 12px 0;
                        }
                        &:nth-child(1) {
                            width: 230px;
                            @media(max-width: $md6+px) {
                                width: 183px;
                            }
                        }
                    }
                }
            }
        }
    }
}