@import '../../../styles/var';

.container {
    position: relative;
    @media(max-width: $md3+px) {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0!important;
    }
}

.tableWr {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md3+px) {
        padding: 0 40px 20px 40px;
        overflow: scroll;
        overflow-y: hidden;
    }
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md5+px) {
        padding: 0 10px 20px 10px;
    }
    @media(max-width: $md6+px) {
        margin-top: 140px;
    }
    table {
        width: 1100px;
        margin: 0 auto;
        font-family: 'Inter';
        font-style: normal;
        color: #2E2E2E;
        border-spacing: 0;
        @media(max-width: $md6+px) {
            width: 850px;
        }
        td {
            transition: all .3s;
            padding: 15px 10px 13px 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            border-bottom: 1px solid #AAAAAA;
            vertical-align: top;
            @media(max-width: $md6+px) {
                font-size: 12px;
                line-height: 15px;
                padding: 13px 10px 13px 0;
            }
            ul {
                display: flex;
                flex-direction: column;
                gap: 5px;
            }
        }
        thead {
            td {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    td {
                        background: #F6F5FF;
                    }
                }
            }
        }
    }
}