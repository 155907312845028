@import "../../../styles/var";
.first {
    text-align: center;
}
.firstBody {
    padding: 144px 0 297px 0;
    position: relative;
    @media (max-width: $md4+px) {
        padding: 120px 0 297px 0;
    }
    @media (max-width: $md5+px) {
        padding: 70px 0 467px 0;
    }
    @media (max-width: 550px) {
        padding: 50px 0 350px 0;
    }
    @media (max-width: $md6+px) {
        padding: 50px 0 300px 0;
    }
    @media (max-width: 400px) {
        padding: 50px 0 270px 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: -5px;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url("../../../assets/img/main/1/1.png") center 0 / contain no-repeat;
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/main/1/1.png") center bottom / 706px 684px no-repeat;
        }
        @media (max-width: 700px) {
            background: url("../../../assets/img/main/1/1.png") center bottom / 650px 600px no-repeat;
        }
        @media (max-width: 650px) {
            background: url("../../../assets/img/main/1/1.png") center bottom / 550px 500px no-repeat;
        }
        @media (max-width: 550px) {
            background: url("../../../assets/img/main/1/1.png") center bottom / 450px 400px no-repeat;
        }
        @media (max-width: $md6+px) {
            background: url("../../../assets/img/main/1/1.png") center bottom / 400px 350px no-repeat;
        }
        @media (max-width: 400px) {
            background: url("../../../assets/img/main/1/1.png") center bottom / 375px 325px no-repeat;
        }
    }
}
.firstTitle {
    max-width: 500px;
    margin: 0 auto 40px auto;
    @media (max-width: $md6+px) {
        max-width: 320px;
    }
}
.firstBtn {
}
.firstWrapper {
    position: relative;
    z-index: 3;
}
