@import '../../../styles/var';

.ourMission {
    position: relative;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
        gap: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
        gap: 80px;
    }
    .block {
        display: grid;
        grid-template-columns: 700px 1fr;
        align-items: center;
        @media(max-width: $md1+px) {
            grid-template-columns: 600px 1fr;
        }
        @media(max-width: $md2+px) {
            grid-template-columns: 550px 1fr;
        }
        @media(max-width: $md3+px) {
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
        @media(max-width: $md6+px) {
            gap: 34px;
        }
        &:nth-child(2) {
            grid-template-columns: 1fr 700px;
            @media(max-width: $md1+px) {
                grid-template-columns: 1fr 600px;
            }
            @media(max-width: $md2+px) {
                grid-template-columns: 1fr 550px;
            }
            @media(max-width: $md3+px) {
                display: flex;
                flex-direction: column-reverse;
                gap: 40px;
            }
            @media(max-width: $md6+px) {
                gap: 34px;
            }
        }
        .content {

            .title {
                text-align: left;
            }
            .text {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                ul {
                    padding-left: 30px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    @media(max-width: $md6+px) {
                        padding-left: 25px;
                    }
                    li {
                        list-style-type: disc;
                        list-style-position: outside;
                    }
                }
            }
        }
        .image {
            display: flex;
            align-items: center;
            .img_01 {
                width: 539px;
                display: flex;
                margin-left: auto;
                margin-right: 50px;
                @media(max-width: $md1+px) {
                    width: 470px;
                    margin-right: 0;
                }
                @media(max-width: $md2+px) {
                    width: 420px;
                }
                @media(max-width: $md5+px) {
                    width: 350px;
                }
                @media(max-width: $md6+px) {
                    width: 296px;
                }
            }
            .img_02 {
                width: 506px;
                display: flex;
                margin-left: 100px;
                @media(max-width: $md1+px) {
                    width: 450px;
                    margin-left: 40px;
                }
                @media(max-width: $md2+px) {
                    width: 400px;
                }
                @media(max-width: $md5+px) {
                    width: 400px;
                    margin: 0 auto;
                }
                @media(max-width: $md6+px) {
                    width: 290px;
                }
            }
        }
    }
}