@import "../../../styles/var";

.sixRowBlack, .sixRowWhite {
    margin-top: -1px;
}

.six {
    padding-bottom: 100px !important;
    @media (max-width: $md4+px) {
        padding-bottom: 90px !important;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 35px !important;
    }
}
.sixBody {
}
.sixTitle {
    margin-bottom: 70px;
    @media (max-width: $md5+px) {
        margin-bottom: 50px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
    }
}
.wowtitle1 {
    color: #fff!important;
}
.wowtitle2 {
    color: #1E1E1E!important;
}
.wowtitle3 {
    color: #D4B180!important;
}
.wowtitle4 {
    color: #FFFFFF!important;
}
.wowtitle1, .wowtitle2, .wowtitle3, .wowtitle4 {
    background: unset!important;
    -webkit-background-clip: unset!important;
    -webkit-text-fill-color: unset!important;
    background-clip: unset!important;
    text-fill-color: unset!important;
    @media(max-width: $md6+px) {
        font-size: 16px!important;
    }
}

.sixRowBlack {
    display: flex;
    &.white {
        .sixRowBlackLeft {
            background-color: #fff;
        }
        .sixRowBlackLeftTitle {
            color: #3935ff;
        }
        .sixRowBlackLeftSubtitle {
            color: #2e2e2e;
        }
    }
    &:nth-child(2) {
        .sixRowBlackRight {
            background: url("../../../assets/img/main/6/1.png") 0 0 / cover no-repeat;
        }
    }

    &:nth-child(4) {
        .sixRowBlackRight {
            background: url("../../../assets/img/main/6/3.png") 0 0 / cover no-repeat;
        }
    }
}
.sixRowBlackLeft {
    flex: 0 1 50%;
    padding: 63px 100px 53px 40px;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: $md1+px) {
        padding: 63px 40px 53px 40px;
    }
    @media (max-width: $md3+px) {
        padding: 40px 25px 40px 25px;
    }
    @media (max-width: 550px) {
        flex: 0 1 100%;
        padding: 0;
        margin-bottom: 30px;
        background: transparent;
        align-items: flex-start;
    }
}
.sixRowBlackLeftTitle {
    text-align: right !important;
    margin-bottom: 30px;
    line-height: 1.1;
    @media (max-width: $md3+px) {
        font-size: 25px;
        margin-bottom: 15px;
    }
    @media (max-width: $md4+px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
    @media (max-width: 550px) {
        font-size: 18px;
        text-align: left !important;
        color: #2e2e2e !important;
    }
}
.sixRowBlackLeftSubtitle {
    text-align: right !important;
    font-weight: 600;
    color: #fff;
    max-width: 700px;
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
    @media (max-width: $md4+px) {
        font-size: 14px;
    }
    @media (max-width: 550px) {
        text-align: left !important;
        color: #2e2e2e !important;
    }
}
.sixRowBlackRight {
    flex: 0 1 50%;
    @media (max-width: 550px) {
        display: none;
    }
}
.sixRowWhite {
    display: flex;
    &.blue {
        .sixRowWhiteRight {
            background-color:#D4B180;
        }
    }
    &:nth-child(3) {
        .sixRowWhiteLeft {
            background: url("../../../assets/img/main/6/2.png") 0 0 / cover no-repeat;
        }
    }
    &:nth-child(5) {
        .sixRowWhiteLeft {
            background: url("../../../assets/img/main/6/4.png") 0 0 / cover no-repeat;
        }
    }
}
.sixRowWhiteLeft {
    flex: 0 1 50%;
    @media (max-width: 550px) {
        display: none;
    }
}
.sixRowWhiteRight {
    flex: 0 1 50%;
    padding: 63px 40px 53px 100px;
    background-color: #1e1e1e;
    @media (max-width: $md1+px) {
        padding: 63px 40px 53px 40px;
    }
    @media (max-width: $md3+px) {
        padding: 40px 25px 40px 25px;
    }
    @media (max-width: 550px) {
        flex: 0 1 100%;
        padding: 0;
        margin-bottom: 30px;
        background: transparent !important;
        align-items: flex-start;
    }
}
.sixRowWhite {
    &:nth-child(5) {
        .sixRowWhiteRight {
            @media (max-width: 550px) {
                margin-bottom: 0;
            }
        }
    }
}
.sixRowBlackRightTitle {
    margin-bottom: 30px;
    text-align: left !important;
    line-height: 1.1;
    @media (max-width: $md3+px) {
        font-size: 25px;
        margin-bottom: 15px;
    }
    @media (max-width: $md4+px) {
        font-size: 20px;
        margin-bottom: 10px;
    }
    @media (max-width: 550px) {
        font-size: 18px;
        text-align: left !important;
        color: #2e2e2e !important;
    }
}
.sixRowBlackRightSubtitle {
    font-weight: 600;
    color: #fff;
    max-width: 550px;
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
    @media (max-width: $md4+px) {
        font-size: 14px;
    }
    @media (max-width: 550px) {
        text-align: left !important;
        color: #2e2e2e !important;
    }
}

.wowsuitledark {
    color: #1E1E1E;
}