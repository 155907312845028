@import "../../../styles/var";
.hours {
}
.hoursBody {
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0;
        margin: auto;
        top: 120px;
        bottom: 0;
        position: absolute;
        width: 1011.67px;
        height: 408.91px;
        background: url("../../../assets/img/support/2/chart.png") 0 0 / contain no-repeat;
        opacity: 0.5;
    }
}
.hoursTitle {
    margin-bottom: 116px;
    @media (max-width: $md5+px) {
        margin-bottom: 100px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.hoursRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 743px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    @media (max-width: 700px) {
        max-width: 400px;
        justify-content: center;
    }
}
.hoursRowColumn {
    flex: 0 1 330px;
    @media (max-width: 700px) {
        margin-bottom: 70px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:nth-child(1) {
        .hoursRowColumnItemImg {
            width: 136px;
            height: 159px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            background: url("../../../assets/img/support/2/1.png") 0 0 / contain no-repeat;
            background-position: center center;
        }
    }
    &:nth-child(2) {
        .hoursRowColumnItemImg {
            width: 203.98px;
            height: 150px;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: -40px;
            background: url("../../../assets/img/support/2/2.png") 0 0 / contain no-repeat;
            background-position: center center;
        }
    }
}
.hoursRowColumnItem {
    max-width: 330px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    padding: 138px 36px 25px 36px;
    box-shadow: 0px 179.729px 70.8022px rgba(0, 0, 0, 0.01), 0px 99.8493px 59.9096px rgba(0, 0, 0, 0.05),
        0px 45.386px 45.386px rgba(0, 0, 0, 0.09), 0px 10.8926px 25.4162px rgba(0, 0, 0, 0.1),
        0px 0px 0px rgba(0, 0, 0, 0.1);

    background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    border-width: 2.5623px 0px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(17.9361px);

    border-radius: 42.7051px;
    @media (max-width: $md1+px) {
        padding: 138px 20px 25px 20px;
    }
    @media (max-width: $md6+px) {
        padding: 138px 36px 25px 36px;
    }
}
.hoursRowColumnItemImg {
}
.hoursRowColumnItemTitle {
    text-align: center;
    font-weight: 800 !important;
    color: #2e2e2e !important;
    margin-bottom: 25px;
}
.hoursRowColumnItemList {
}
.hoursRowColumnItemListItem {
    color: #2e2e2e !important;
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        content: "";
        display: inline-block;
        left: 5px;
        top: 10px;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #373737;
        @media (max-width: $md4+px) {
            top: 8px;
        }
        @media (max-width: $md6+px) {
            top: 6px;
        }
    }
}
