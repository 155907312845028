@import '../../../styles/var';

.ourGoal {
    position: relative;
    margin-top: 150px;
    display: grid;
    grid-template-columns: 1fr 700px;
    align-items: center;
    @media(max-width: $md1+px) {
        grid-template-columns: 1fr 600px;
    }
    @media(max-width: $md2+px) {
        grid-template-columns: 1fr 550px;
    }
    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 50px;
    }
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .image {
        display: flex;
        align-items: center;
        img {
            display: flex;
            margin-left: 200px;
            width: 353px;
            @media(max-width: $md1+px) {
                margin-left: 100px;
                width: 320px;
            }
            @media(max-width: $md3+px) {
                margin: 0 auto;
            }
            @media(max-width: $md6+px) {
                width: 211px;
            }
        }
    }
    .content {
        .title {
            text-align: left;
        }
        .text {
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            ul {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                @media(max-width: $md6+px) {
                    padding-left: 25px;
                }
                li {
                    list-style-position: outside;
                    list-style-type: disc;
                }
            }
        }
    }
}