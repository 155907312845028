@import '../../../styles/var';

.howToCreate {
    position: relative;
    z-index: 5;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        max-width: 700px;
        text-align: left;
    }
    .content {
        margin: 30px 0 50px 0;
        display: flex;
        justify-content: space-between;
        @media(max-width: $md3+px) {
            flex-direction: column;
            gap: 15px;
        }
        .text {
            line-height: 140%!important;
            width: 700px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                width: 600px;
            }
            @media(max-width: $md2+px) {
                width: 550px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
            }
        }
    }
}