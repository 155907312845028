@import '../../../styles/var';

.container {
    @media(max-width: $md3+px) {
        width: 100%!important;
        max-width: 100%!important;
        padding: 0!important;
    }
}

.tradingPlans {
    position: relative;
    padding-top: 150px;
    @media(max-width: $md4+px) {
        padding-top: 120px;
    }
    @media(max-width: $md6+px) {
        padding-top: 80px;
    }
    .title {
        position: relative;
        z-index: 3;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 42px;
        line-height: 51px;
        background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(76.82deg, #576265 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #576265 55.72%, #576265 77.23%, #757A7B 85.34%, #576265 91.31%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        background-blend-mode: color, overlay, normal;
        text-align: center;
        @media(max-width: $md3+px) {
            padding: 0 40px;
        }
        @media(max-width: $md4+px) {
            font-size: 38px;
            line-height: 46px;
        }
        @media(max-width: $md6+px) {
            font-size: 25px;
            line-height: 30px;
            padding: 0 10px;
        }
    }
    .cards {
        position: relative;
        z-index: 30;
        margin: 150px 0 50px 0;
        // display: flex;
        // justify-content: space-around;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 100px 20px;
        @media(max-width: $md1+px) {
            margin: 125px 0 50px 0;
        }
        @media(max-width: $md2+px) {
            justify-content: space-between;
        }
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .card {
        position: relative;
        z-index: 30;
        width: 400px;
        margin: 0 auto;
        border-radius: 42px;
        padding: 0 35px 30px 35px;
        -webkit-box-shadow: 0px 15px 36px 5px rgba(189,185,189,1);
        -moz-box-shadow: 0px 15px 36px 5px rgba(189,185,189,1);
        box-shadow: 0px 15px 36px 5px rgba(189,185,189,1);
        transition: all .3s;
        &:hover {
            @media(min-width: 1221px) {
                border-radius: 42px 42px 10px 10px;
                -webkit-box-shadow: unset;
                -moz-box-shadow: unset;
                box-shadow: unset;
                background: #F6F0DD;
                .hidden {
                    pointer-events: visible;
                    opacity: 1;
                    background: #F6F0DD;
                    border-radius: 0 0 42px 42px;
                    top: calc(100% - 20px);
                }
                .dots {
                    opacity: 0;
                }
            }
        }
        &:nth-child(1), &:nth-child(2) {
            &:hover {
                border-radius: 42px;
            }
        }
        &:nth-child(4) {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 3;
        }
        &:nth-child(5) {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 4;
        }
        @media(max-width: $md1+px) {
            width: 380px;
        }
        @media(max-width: $md3+px) {
            height: 100%;
            padding-top: 87px;
            width: 100%;
            -webkit-box-shadow: 0px 7px 26px 5px rgba(189,185,189,1);
            -moz-box-shadow: 0px 7px 26px 5px rgba(189,185,189,1);
            box-shadow: 0px 7px 26px 5px rgba(189,185,189,1);
        }
        @media(max-width: $md4+px) {
            width: 100%;
            padding: 80px 30px 50px 30px;
        }
        @media(max-width: $md6+px) {
            padding: 70px 20px 30px 20px;
        }
        &Image {
            height: 129px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            margin-top: -67px;
            @media(max-width: $md1+px) {
                height: 100px;
            }
            @media(max-width: $md3+px) {
                margin-top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                position: absolute;
                top: -50px;
            }
        }
        &Title {
            text-align: center;
            margin: 20px 0 15px 0;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            color: #2E2E2E;
            @media(max-width: $md3+px) {
                font-size: 18px;
                line-height: 22px;
                margin-top: 0;
            }
        }
        &Subtitle {
            text-align: center;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #7B622F;
            @media(max-width: $md4+px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
        ul {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #2E2E2E;
            margin-left: 20px;
            @media(max-width: $md4+px) {
                font-size: 16px;
                line-height: 19px;
                margin-top: 20px;
                gap: 13px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                line-height: 17px;
            }
            li {
                list-style-type: disc;
                list-style-position: outside;
            }
        }
        .swiperHidden {
            margin-top: 10px;
        }
        .hidden {
            pointer-events: none;
            // background-color: grey;
            transition: all .3s;
            position: absolute;
            z-index: 40;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 0 35px;
            opacity: 0;
            border-radius: 42px;
            padding-bottom: 30px;
            ul {
                margin-top: 0;
            }
        }
        .dots {
            position: absolute;
            right: 0;
            left: 0;
            margin: 0 auto;
            bottom: 10px;
            mix-blend-mode: darken;
            transition: all .3s;
            width: 30px;
            opacity: 0.5;
        }
    }
    .swiperWr {
        position: relative;
        z-index: 3;
        display: none;
        margin: 20px 0 40px 0;
        @media(max-width: $md3+px) {
            display: flex;
        }
        @media(max-width: $md6+px) {
            margin: 20px 0 40px 0;
        }
    }
    .btn {
        text-align: center;
        position: relative;
        z-index: 5;
    }
}