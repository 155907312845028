@import '../../../styles/var';

.startTrading {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: center;
    }
    .steps {
        position: relative;
        margin: 250px auto 190px auto;
        width: 1000px;
        display: flex;
        justify-content: space-between;
        @media(max-width: $md1+px) {
            margin-top: 220px;
        }
        @media(max-width: $md2+px) {
            width: 900px;
        }
        @media(max-width: $md3+px) {
            width: 800px;
        }
        @media(max-width: $md4+px) {
            width: 550px;
            margin: 190px auto 170px auto;
        }
        @media(max-width: $md5+px) {
            width: 400px;
            flex-direction: column;
            justify-content: unset;
            gap: 50px;
            margin: 80px auto;
        }
        @media(max-width: $md6+px) {
            width: 100%;
            margin: 50px 0;
        }
        &::before {
            content: '';
            position: absolute;
            width: 90%;
            height: 4px;
            background-color: #2E2E2E;
            top: calc(50% - 2px);
            left: 5%;
            @media(max-width: $md5+px) {
                width: 2px;
                height: 90%;
                top: 5%;
                left: 49px;
            }
            @media(max-width: $md6+px) {
                left: 35px;
            }
        }
        .step {
            position: relative;
            @media(max-width: $md5+px) {
                align-items: center;
                gap: 20px;
                display: grid;
                grid-template-columns: 100px 1fr;
            }
            @media(max-width: $md6+px) {
                grid-template-columns: 70px 1fr;
            }
            &_01 {
                .stepContent {
                    width: 221px;
                    left: calc(50% - 221px / 2);
                }
                .stepNum {
                    border: 5px solid #2E2E2E;
                    @media(max-width: $md6+px) {
                        border-width: 3px;
                    }
                }
            }
            &_02 {
                .stepContent {
                    width: 321px;
                    left: calc(50% - 321px / 2);
                    bottom: unset;
                    top: calc(100% + 25px);
                }
                .stepNum {
                    border: 8px solid #2E2E2E;
                    @media(max-width: $md6+px) {
                        border-width: 4px;
                    }
                }
            }
            &_03 {
                .stepContent {
                    width: 175px;
                    left: calc(50% - 175px / 2);
                }
                .stepNum {
                    border: 12px solid #2E2E2E;
                    @media(max-width: $md6+px) {
                        border-width: 6px;
                    }
                }
            }
            &Content {
                position: absolute;
                bottom: calc(100% + 25px);
                text-align: center;
                @media(max-width: $md5+px) {
                    top: unset!important;
                    bottom: unset!important;
                    left: unset!important;
                    right: unset!important;
                    text-align: left;
                    position: relative;
                }
                @media(max-width: $md6+px) {
                    width: 100%!important;
                }
                .stepTitle {
                    font-weight: 600!important;
                }
                .stepText {
                    margin-top: 15px;
                    @media(max-width: $md5+px) {
                        margin-top: 5px;
                    }
                    @media(max-width: $md6+px) {
                        margin-top: 0px;
                    }
                }
            }
            &Num {
                width: 140px;
                height: 140px;
                background: #FFFFFF;
                border-radius: 100px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 800;
                font-size: 90px;
                line-height: 109px;
                color: #2E2E2E;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width: $md4+px) {
                    width: 100px;
                    height: 100px;
                    font-size: 64px;
                    line-height: 80px;
                }
                @media(max-width: $md6+px) {
                    width: 70px;
                    height: 70px;
                    font-size: 50px;
                    line-height: 61px;
                }
                span {
                    text-align: center;
                }
            }
        }
    }
    .btn {
        text-align: center;
    }
}