@import '../../../styles/var';

.faq {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: center;
    }
    .tabs {
        max-width: 862px;
        margin: 70px auto 0 auto;
        display: flex;
        flex-direction: column;
        gap: 20px;
        @media(max-width: $md4+px) {
            margin-top: 50px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .tab {
            cursor: pointer;
            border: 1px solid #2E2E2E;
            border-radius: 20px;
            padding: 13px 20px;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            @media(max-width: $md6+px) {
                padding: 5px 15px;
            }
            &Title {
                position: relative;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #2E2E2E;
                // display: flex;
                align-items: center;
                // justify-content: space-between;
                gap: 10px;
                display: grid;
                grid-template-columns: 1fr 14px;
                @media(max-width: $md6+px) {
                    font-size: 13px;
                    line-height: 20px;
                }
                &Status {
                    width: 14px;
                    height: 14px;
                    position: relative;
                    &.activeStatus {
                        &::before {
                            transform: rotate(-90deg);
                        }
                    }
                    &::before {
                        transition: all .3s;
                        content: '';
                        position: absolute;
                        width: 2px;
                        height: 100%;
                        top: 0;
                        left: calc(50% - 1px);
                        background: #2E2E2E;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        top: calc(50% - 1px);
                        left: 0;
                        background: #2E2E2E;
                    }
                }
            }
            &Content {
                width: 93%;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                color: #2E2E2E;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                @media(max-width: $md6+px) {
                    font-size: 12px;
                    gap: 10px;
                    width: 100%;
                }
                ol {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    li {
                        list-style-position: outside;
                    }
                }
            }
        }
    }
}