@import '../../../styles/var';

.leadingBroker {
    position: relative;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
        gap: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
        gap: 80px;
    }
    .block {
        display: grid;
        grid-template-columns: 700px 1fr;
        align-items: center;
        &:nth-child(2) {
            grid-template-columns: 1fr 700px;
            flex-direction: column-reverse;
        }
        @media(max-width: $md3+px) {
            display: flex;
            gap: 60px;
            flex-direction: column;
        }
        @media(max-width: $md5+px) {
            gap: 30px;
        }
        .content {
            display: flex;
            flex-direction: column;
            gap: 30px;
            .title {
                text-align: left;
            }
            .subtitle {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 800;
                font-size: 30px;
                line-height: 120%;
                background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(81.72deg, #576265 0.65%, #9EA1A1 16.74%, #848B8A 43.43%, #576265 52.43%, #576265 77.67%, #757A7B 87.18%, #576265 94.18%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                // background-blend-mode: color, overlay, normal;
                display: inline-flex;
                @media(max-width: $md6+px) {
                    font-size: 25px;
                }
            }
            .text {
                display: flex;
                flex-direction: column;
                gap: 15px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 140%;
                color: #2E2E2E;
                @media(max-width: $md4+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
        }
        .image {
            display: flex;
            align-items: center;
            text-align: center;
            img {
                display: flex;
            }
            .image_01 {
                height: 669px;
                margin: 0 auto;
                transform: translateX(160px);
                @media(max-width: $md1+px) {
                    height: 550px;
                    transform: translateX(100px);
                }
                @media(max-width: $md2+px) {
                    height: 520px;
                    transform: translateX(50px);
                }
                @media(max-width: $md3+px) {
                    transform: translateX(0);
                }
                @media(max-width: $md5+px) {
                    height: 284px;
                }
            }
            .image_02 {
                width: 761px;
                @media(max-width: $md1+px) {
                    width: 520px;
                }
                @media(max-width: $md2+px) {
                    width: 450px;
                }
                @media(max-width: $md3+px) {
                    width: 550px;
                }
                @media(max-width: $md5+px) {
                    width: 335px;
                }
            }
            .image_03 {
                margin-left: auto;
                height: 503px;
                @media(max-width: $md1+px) {
                    height: 450px;
                }
                @media(max-width: $md2+px) {
                    height: 400px;
                }
                @media(max-width: $md5+px) {
                    height: 301px;
                }
            }
        }
    }
}