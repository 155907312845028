@import '../../../styles/var';

.howToCreate {
    position: relative;
    margin-top: 150px;
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        max-width: 700px;
        text-align: left;
    }
    .content {
        margin: 30px 0 70px 0;
        display: flex;
        justify-content: space-between;
        @media(max-width: $md3+px) {
            flex-direction: column;
            gap: 15px;
        }
        .text {
            line-height: 140%!important;
            width: 700px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                width: 600px;
            }
            @media(max-width: $md2+px) {
                width: 550px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
            }
            b {
                font-weight: 600;
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-left: 25px;
            @media(max-width: $md6+px) {
                padding-left: 20px;
            }
            li {
                list-style-position: outside;
                list-style-type: disc;
            }
        }
    }
    .tabs {
        .label {
            position: absolute;
            left: 0; top: -2px;
            background:#1bb1a5;
            box-shadow: 0 2px 5px -1px rgba(0,0,0,0.3);
            border-radius: 100px;		
            height: 100%;
            transition: 500ms;
        }
        .buttons {
            display: flex;
            align-items: center;
            background-color: transparent;
            max-width: 1068px;
            height: 50px;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            border-radius: 8px;

            @media(max-width: $md4+px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                height: auto;
                gap: 20px 20px;
            }
            @media(max-width: 700px) {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px 10px;
            }
            li {
                text-align: center;		
                flex: auto;
                position: relative;
                z-index: 2;		
                cursor: pointer;
                transition: 300ms cubic-bezier(0.86, 0, 0.33, 0.26);
                font-weight: 800;
                font-size: 20px;
                line-height: 30px;
                color: #373737;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                @media(max-width: $md4+px) {
                    padding: 0;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;
                    transition: all .3s;
                    font-size: 16px;
                    border: 1px solid #e7d69d;
                    border-radius: 20px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    height: 40px;
                    font-weight: 400;
                }
            }
            li.btnActive {
                color: #fff;
                @media(max-width: $md4+px) {
                    color: #000;
                    background-color: #e7d69d;
                }		
            }
            .label {
                position: absolute;
                left: 0; 
                top: 0;
                background: linear-gradient(89.75deg, #63490E -3.71%, #C9B46E 103.46%);
                height: 100%;
                transition: 500ms;
                border-radius: 0;
                filter: blur(30px);
                @media(max-width: $md4+px) {
                    display: none;
                }
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            gap: 15px;
            text-align: center;
            max-width: 700px;
            margin: 60px auto 0 auto;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            color: #2E2E2E;
            @media(max-width: $md4+px) {
                margin-top: 40px;
            }
            @media(max-width: $md6+px) {
                margin-top: 30px;
                font-size: 14px;
            }
        }
    }
}