@import "../../../styles/var";

.btn {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: $fontfamily;
    font-weight: 800;
    font-size: 16px;
    color: #ffffff;
    padding: 13px 30px;
    // background: #3935ff;
    // background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(84.95deg, #576265 8.14%, #9EA1A1 30.21%, #848B8A 66.84%, #576265 79.18%, #576265 113.81%, #757A7B 126.87%, #576265 136.47%);
    // background-blend-mode: color, overlay, normal;
    border-radius: 20px;
    display: inline-block;
    transition: all 0.3s linear;
    cursor: pointer;
    // border: 1px solid transparent;
    &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        border: 1px solid transparent;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        transition: all .3s;
        z-index: 18;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #C1A875, #C1A875), linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #FFFFFF 95.95%), linear-gradient(84.95deg, #576265 8.14%, #9EA1A1 30.21%, #848B8A 66.84%, #576265 79.18%, #576265 113.81%, #757A7B 126.87%, #576265 136.47%);
        background-blend-mode: color, overlay, normal;
        border-radius: 20px;
        transition: all .3s;
        opacity: 1;
        z-index: 18;
    }
    &:hover {
        color: #C1A875;
        &::before {
            border: 1px solid #C1A875;
        }
        &::after {
            opacity: 0;
        }
    }
    span {
        position: relative;
        z-index: 20;
    }
    &.white {
        // color: #3935ff;
        color: #1F1F1F;
        // background: #ffffff;
        // border: 1px solid #ffffff;
        &:hover {
            color: #C1A875;
            // background: #3935ff;
        }
    }
}
